import axios from 'axios';
import { getToken } from './auth';

const api = axios.create({
  baseURL: 'https://api.codenroll.app',
});
const token = getToken();

api.interceptors.request.use(
  async config => {
    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    // handle the response error
    return Promise.reject(`error axios: ${  error}`);
  }
);

export default api;
