import React, { useState } from 'react';
import api from "../../../services/api";
import {getToken, login} from "../../../services/auth";

export default function Logon({ history }) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('');
    const [error, setError] = useState('');

    async function hadlesubmit(e){
        e.preventDefault();

      if (!name || !email || !password || !role) {
        setError("Fill in all required fields");

      } else {
        try {
          const data = new FormData();

          data.append('name', name);
          data.append('email', email);
          data.append('password', password);
          data.append('role', role);

          await api.post('/users', {name, email, password, role})
            .then(result => {
              if (result.status === 200) {
                localStorage.setItem("user.name", result.data.user.name);
                localStorage.setItem("user.email", result.data.user.email);
                localStorage.setItem("user.id", result.data.user.id);
                localStorage.setItem("user.role", result.data.user.role);
                login(result.data.token);
                history.push("/dashboard");
              } else {
                setError(result.error);
              }
            }).catch(e => {
              console.log(e.response);
              setError(e.response.data ? e.response.data.error : e.message);
            });
        } catch (err) {
          console.log(err.message);
          setError(err.message);
        }
      }

    }

    return (

          <section style={{margin: "85px 0 10px 0"}}>
            <div className="home-center">

                <div className="container">
                  <div className="row">
                    <div className="col-lg-6 col-md-6">
                      <div className="mr-lg-5">
                        <img src="assets/images/signup.png" className="img-fluid" alt="" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="login_page bg-white shadow rounded p-4">
                        <div className="text-center">
                          <h4 className="mb-4">Signup</h4>
                        </div>

                        {error &&
                        <div className="text-center">
                          <h6 className="mb-5">{error}</h6>
                        </div>
                        }

                        <form className="login-form" onSubmit={hadlesubmit}>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group position-relative">
                                <label>Your name <span className="text-danger">*</span></label>
                                <i className="mdi mdi-account ml-3 icons"> </i>
                                <input
                                  type="text"
                                  className="form-control pl-5"
                                  placeholder="Name"
                                  required=""
                                  kl_vkbd_parsed="true"
                                  id="name"
                                  value={name}
                                  onChange={event => setName(event.target.value)}/>
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group position-relative">
                                <label>Your Email <span className="text-danger">*</span></label>
                                <i className="mdi mdi-email ml-3 icons"> </i>
                                <input
                                  type="email"
                                  className="form-control pl-5"
                                  placeholder="Email"
                                  name="email"
                                  required=""
                                  kl_vkbd_parsed="true"
                                  id="email"
                                  value={email}
                                  onChange={event => setEmail(event.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group position-relative">
                                <label>Password <span className="text-danger">*</span></label>
                                <i className="mdi mdi-key ml-3 icons"> </i>
                                <input
                                  type="password"
                                  className="form-control pl-5"
                                  placeholder="Password"
                                  required=""
                                  kl_vkbd_parsed="true"
                                  id="pass"
                                  value={password}
                                  onChange={event => setPassword(event.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group position-relative">
                                <label>Role <span className="text-danger">*</span></label>
                                <i className="mdi mdi-access-point ml-3 icons"> </i>
                                <input
                                  type="text"
                                  className="form-control pl-5"
                                  placeholder="Your job title"
                                  required=""
                                  kl_vkbd_parsed="true"
                                  id="role"
                                  value={role}
                                  onChange={event => setRole(event.target.value)}
                                />
                              </div>
                            </div>

                            <div className="col-md-12">
                              <div className="form-group">
                                <div className="custom-control custom-checkbox">
                                  <input type="checkbox" className="custom-control-input" id="customCheck1" kl_vkbd_parsed="true" />
                                    <label className="custom-control-label" htmlFor="customCheck1">I Accept
                                      <a href="/terms" className="text-primary">Terms And Condition</a></label>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <button type="submit" className="btn btn-primary w-100">Register</button>
                            </div>

                            <div className="mx-auto">
                              <p className="mb-0 mt-3">
                                <small className="text-dark mr-2">
                                Already have an account?
                              </small>
                                <a href="/login" className="text-dark font-weight-bold">Sign in</a>
                              </p>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>

                  </div>

                </div>
            </div>
          </section>

    )
}
