import React from 'react';
import Loading from "../../components/loading";

class FAQ extends React.Component {

    render(){

        return(

            <React.Fragment>

                <Loading />

                <section className="bg-half bg-light">
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 text-center">
                                        <div className="page-next-level">
                                            <h4 className="title"> Frequently Asked Questions  </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-9">
                                <div className="p-4 shadow border rounded">

                                    <div className="faq-content mt-4">
                                        <div className="accordion" id="accordionExample">
                                            <div className="card border rounded shadow mb-2">
                                                <a data-toggle="collapse" href="#collapseOne"
                                                   className="faq position-relative" aria-expanded="true"
                                                   aria-controls="collapseOne">
                                                    <div className="card-header bg-light p-3" id="headingOne">
                                                        <h4 className="title mb-0 faq-question"> How many days can I work in a company? </h4>
                                                    </div>
                                                </a>
                                                <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                                     data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        <p className="text-muted mb-0 faq-ans">The company decides the number of days available so that each interested developer can experience the business and culture.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card border rounded shadow mb-2">
                                                <a data-toggle="collapse" href="#collapseTwo"
                                                   className="faq position-relative collapsed" aria-expanded="false"
                                                   aria-controls="collapseTwo">
                                                    <div className="card-header bg-light p-3" id="headingTwo">
                                                        <h4 className="title mb-0 faq-question"> How many spaces are available for each company? </h4>
                                                    </div>
                                                </a>
                                                <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                                     data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        <p className="text-muted mb-0 faq-ans">Each company determines the amount of spaces available.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card border rounded shadow mb-2">
                                                <a data-toggle="collapse" href="#collapseThree"
                                                   className="faq position-relative collapsed" aria-expanded="false"
                                                   aria-controls="collapseThree">
                                                    <div className="card-header bg-light p-3" id="headingThree">
                                                        <h4 className="title mb-0 faq-question"> How is the developer's experience calculated? </h4>
                                                    </div>
                                                </a>
                                                <div id="collapseThree" className="collapse" aria-labelledby="headingThree"
                                                     data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        <p className="text-muted mb-0 faq-ans">The version control system has been around for a long time; we trust that the registration date on one of these systems and consider it the beginning of the developer's experience.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card border rounded shadow mb-2">
                                                <a data-toggle="collapse" href="#collapseFour"
                                                   className="faq position-relative collapsed" aria-expanded="false"
                                                   aria-controls="collapseFour">
                                                    <div className="card-header bg-light p-3" id="headingFour">
                                                        <h4 className="title mb-0 faq-question"> How are the programming languages that the developer knows determined? </h4>
                                                    </div>
                                                </a>
                                                <div id="collapseFour" className="collapse" aria-labelledby="headingFour"
                                                     data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        <p className="text-muted mb-0 faq-ans">Each developer code repository has the programming languages used, so we can identify which languages the developer is familiar with.</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="card rounded shadow mb-0">
                                                <a data-toggle="collapse" href="#collapseFive"
                                                   className="faq position-relative collapsed" aria-expanded="false"
                                                   aria-controls="collapseFive">
                                                    <div className="card-header bg-light p-3" id="headingFive">
                                                        <h4 className="title mb-0 faq-question"> How is the developer's level of knowledge determined? </h4>
                                                    </div>
                                                </a>
                                                <div id="collapseFive" className="collapse" aria-labelledby="headingFive"
                                                     data-parent="#accordionExample">
                                                    <div className="card-body">
                                                        <p className="text-muted mb-0 faq-ans">We perform a calculation from the developer's repositories to measure their experience in each language.</p>
                                                    </div>
                                                </div>
                                            </div>

                                          <div className="card rounded shadow mb-0">
                                            <a data-toggle="collapse" href="#collapseSix"
                                               className="faq position-relative collapsed" aria-expanded="false"
                                               aria-controls="collapseSix">
                                              <div className="card-header bg-light p-3" id="headingSix">
                                                <h4 className="title mb-0 faq-question"> Will my private repositories be visible to anyone? </h4>
                                              </div>
                                            </a>
                                            <div id="collapseSix" className="collapse" aria-labelledby="headingSix"
                                                 data-parent="#accordionExample">
                                              <div className="card-body">
                                                <p className="text-muted mb-0 faq-ans">No. We perform our analysis only on public repositories.</p>
                                              </div>
                                            </div>
                                          </div>

                                          <div className="card rounded shadow mb-0">
                                            <a data-toggle="collapse" href="#collapseSeven"
                                               className="faq position-relative collapsed" aria-expanded="false"
                                               aria-controls="collapseSeven">
                                              <div className="card-header bg-light p-3" id="headingSeven">
                                                <h4 className="title mb-0 faq-question"> Do I have more experience than Codenroll shows? </h4>
                                              </div>
                                            </a>
                                            <div id="collapseSeven" className="collapse" aria-labelledby="headingSeven"
                                                 data-parent="#accordionExample">
                                              <div className="card-body">
                                                <p className="text-muted mb-0 faq-ans">As we look at only public repositories, it may be that a developer has a lower grade than what he deserves.</p>
                                              </div>
                                            </div>
                                          </div>

                                        </div>
                                    </div>

                                    <div className="mt-3">
                                        <a href="#" className="btn btn-primary mt-2 mr-2">Accept</a>
                                        <a href="#" className="btn btn-outline-primary mt-2">Decline</a>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </section>

            </React.Fragment>

        );
    }
}

export default FAQ;
