import React from 'react';
import { DateRange } from "react-date-range";
import { format, addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import "./calendar.css";

class CalendarDateRange extends React.Component {

    constructor(props, context) {
        super(props, context);

        this.state = {
            maxDays: 1,
            disabledDates: this.props.disabledDates,
            dateRange: {
                selection: {
                    startDate: addDays(new Date(), 1),
                    endDate: addDays(new Date(), 1),
                    key: 'selection',
                },
            },
            selectDate: [],
        };
    }
    demoMethod(payload){
        this.props.sendData(payload);
    }

    handleRangeChange(which, payload) {
        // console.log(which, payload);
        this.setState({
            [which]: {
                ...this.state[which],
                ...payload,
            },
        });
        this.demoMethod(payload);
    }

    render(){

        return (
            <DateRange
                onChange={this.handleRangeChange.bind(this, 'dateRange')}
                months={2}
                direction={"horizontal"}
                ranges={[this.state.dateRange.selection]}
                className={'PreviewArea'}
                disabledDates={this.state.disabledDates}
                minDate={addDays(new Date(), 1)}
            />
        );
    }
}

export default CalendarDateRange;
