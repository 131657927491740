import React, { Component } from "react";
import Switch from "react-switch";
import Likes from "./likes";
import api from "../services/api";
import {logout} from "../services/auth";

class SwitchButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checked: this.props.checked ? this.props.checked : false,
            booking_id: this.props.booking_id,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    async handleChange(checked) {
        //this.setState({ checked });
        let booking_id = this.state.booking_id;
        let url = `/spots/booking/${booking_id}/approve`;
        if(this.state.checked) {
            url = `/spots/booking/${booking_id}/disapproved`;
        }

        await api.post(url, {})
            .then(r => {
                let b = r.data.success ? r.data.data.approved : false;
                this.setState({ checked: b });
            }).catch(error => {
                console.log(error);
            });
    }

    render() {
        return (
            <label>
                <span style={{fontSize:"10px", float:"right", margin:"7px 0 0 10px"}}>{this.state.checked ? "approved" : "disapproved"}</span>
                <Switch onChange={this.handleChange} checked={this.state.checked} />
            </label>
        );
    }
}

export default SwitchButton;
