import React, { useEffect, useState, useMemo } from 'react';
import Loading from '../../components/loading';
import api from '../../services/api';
import { getToken, logout } from '../../services/auth';

import camera from '../../assets/images/camera.svg';
import './index.css';

export default function UpdateCompany( { history, match } ) {


    const id = match.params.id;

    let [name, setName] = useState('');
    let [techs, setTechs] = useState('');
    // let [price, setPrice] = useState('');
    let [thumbnail, setThumbnail] = useState(null);
    let [about, setAbout] = useState('');
    let [address, setAddress] = useState('');
    let [city, setCity] = useState('');
    let [zipcode, setZipcode] = useState('');
    let [positions, setPositions] = useState('');
    let [days, setDays] = useState('');
    const [error, setError] = useState('');

    useEffect(() => {
        async function loadUsers(){
            const response = await api.get(`/spots/${match.params.id}`)
                .then(response => {

                    const company = response.data.data;
                    setTechs(company.techs.map(t => { return  t.tech }).toString());
                    setAbout(company.about);
                    setPositions(company.positions);
                    setZipcode(company.address.zip_code);
                    setCity(company.address.city);
                    const add = `${company.address.street_name}, ${company.address.street_number}`;
                    setAddress(add);
                    setThumbnail(company.image.path);
                    // setPrice(company.price);
                    setName(company.name);
                    setDays(company.days);

                }).catch(error => {
                    if(error.message.indexOf("401") !== -1) {
                        logout();
                        history.push("/login");
                    }
                });

        }
        loadUsers();
    }, [match.params.id]);


    const preview = useMemo(()=> {
        try {
            return thumbnail ? URL.createObjectURL(thumbnail) : null;
        } catch (e) {
            return thumbnail
        }

    },[thumbnail]);

    async function handleSubmit(e) {
        e.preventDefault();

        const data = new FormData();


        await api.put(`/spots/${id}`, {name, about, address, thumbnail, techs, city, zipcode, positions, days})
            .then(response => {
              if (response.status === 200) {
                history.push('/dashboard');
              }
              setError(response.error);
            })
            .catch(e => {
              setError(e.response.data ? e.response.data.error : e.message);
            });

    }

    return (

        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-7">
                        <div className="custom-form">

                          {error &&
                          <div className="text-center">
                            <h6 className="mb-5">{error}</h6>
                          </div>
                          }

                            <form onSubmit={handleSubmit} className="rounded shadow p-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group position-relative">

                                            <label
                                                id="thumbnail"
                                                style={{ backgroundImage: `url(${preview})`}}
                                                className={thumbnail ? 'has-thumbnail' : ''}>
                                                <input type="file" onChange={event => setThumbnail(event.target.files[0])}/>
                                                <img src={camera} alt="Select"/>
                                            </label>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Company :</label>
                                            <i className="mdi mdi-domain ml-3 icons"> </i>

                                            <input
                                                id="company"
                                                className="form-control pl-5"
                                                placeholder="Sua empresa incrível"
                                                value={name}
                                                onChange={event => setName(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Address :</label>
                                            <i className="mdi mdi-bank-outline ml-3 icons"> </i>

                                            <input
                                                id="address"
                                                className="form-control pl-5"
                                                placeholder="Avenida Paulista, 222 4º andar"
                                                value={address}
                                                onChange={event => setAddress(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>City :</label>
                                            <i className="mdi mdi-city-variant ml-3 icons"> </i>
                                            <input
                                                id="city"
                                                className="form-control pl-5"
                                                placeholder="São Paulo"
                                                value={city}
                                                onChange={event => setCity(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Zip code :</label>
                                            <i className="mdi mdi-home-map-marker ml-3 icons"> </i>
                                            <input
                                                id="zipcode"
                                                className="form-control pl-5"
                                                placeholder="05040000"
                                                value={zipcode}
                                                onChange={event => setZipcode(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Positions :</label>
                                            <i className="mdi mdi-dev-to ml-3 icons"> </i>
                                            <input
                                                id="positions"
                                                className="form-control pl-5"
                                                type="number"
                                                placeholder="01"
                                                value={positions}
                                                onChange={event => setPositions(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Days :<span className="text-info"> (how many days)</span></label>
                                            <i className="mdi mdi-calendar ml-3 icons"></i>
                                            <input
                                                className="form-control pl-5"
                                                id="days"
                                                type="number"
                                                placeholder="days"
                                                value={days ? days : 0}
                                                onChange={event => setDays(event.target.value)}
                                            />
                                        </div>
                                    </div>

                                    {/*<div className="col-md-4">*/}
                                    {/*    <div className="form-group position-relative">*/}
                                    {/*        <label>Price :<span className="text-info"> (leave blank for FREE)</span></label>*/}
                                    {/*        <i className="mdi mdi-currency-usd ml-3 icons"></i>*/}
                                    {/*        <input*/}
                                    {/*            className="form-control pl-5"*/}
                                    {/*            id="price"*/}
                                    {/*            type="number"*/}
                                    {/*            placeholder="Valor cobrado por dia"*/}
                                    {/*            value={price ? price : 0}*/}
                                    {/*            onChange={event => setPrice(event.target.value)}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Techs :<span className="text-info">* (comma separated)</span></label>
                                            <i className="mdi mdi-code-braces ml-3 icons"> </i>

                                            <input
                                                id="techs"
                                                className="form-control pl-5"
                                                placeholder="Tecnologias e metodologias usadas na sua empresa"
                                                value={techs.toString()}
                                                onChange={event => setTechs(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group position-relative">
                                            <label>Description : </label>
                                            <i className="mdi mdi-comment-text-outline ml-3 icons"></i>
                                            <textarea
                                                id="about"
                                                placeholder="Startup studio ajudando a criar novas startups"
                                                onChange={event => setAbout(event.target.value)}
                                                value={about}
                                                name="about"
                                                rows="4"
                                                className="form-control pl-5"
                                            >

                                            </textarea>
                                        </div>
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <input type="submit" id="submit" name="send"
                                               className="submitBnt btn btn-primary" value="Update" />
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>
                </div>
            </div>

        </section>

    )
}
