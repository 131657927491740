import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import api from '../../services/api';
import Likes from "../../components/likes";

class Devs extends React.Component {

  constructor(props, context) {
    super(props, context);
    this.state = {
      devs: this.props.devs,
    };
  }

  async componentDidMount() {
    const token = localStorage.getItem('user');
    const dev = await api.get('/devs');
console.log(dev.data.data);
    this.setState({
      devs: dev.data.data,
    });
  }

  render() {
    return (
      <section className="section bg-light">

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 text-center">
              <div className="section-title mb-60">
                <h4 className="main-title mb-4">Trusted Developers</h4>
              </div>
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-lg-12 text-center">
              <div id="customer-testi" className=" owl-theme">

                {this.state.devs && this.state.devs.map(dev => (
                  <div className="customer-testi m-2 text-left" key={dev.id}>
                    <img src={dev.avatar} height="80" className="rounded shadow float-left mr-3"
                         alt=""/>
                    <div className="content overflow-hidden d-block p-3 shadow rounded">
                      <ul className="list-unstyled mb-0">
                        <li className="list-inline-item"><i
                          className="mdi mdi-star text-warning"> </i></li>
                        <li className="list-inline-item"><i
                          className="mdi mdi-star text-warning"> </i></li>
                        <li className="list-inline-item"><i
                          className="mdi mdi-star text-warning"> </i></li>
                        <li className="list-inline-item"><i
                          className="mdi mdi-star text-warning"> </i></li>
                        <li className="list-inline-item"><i
                          className="mdi mdi-star text-warning"> </i></li>
                      </ul>
                      <p className="text-muted mt-2">{dev.bio}</p>
                      <h6 className="text-primary"><Link to={`/devs/${dev.nickname}`}> {dev.nickname} </Link> </h6>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

};

export default Devs;
