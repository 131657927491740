import React from "react";
import { Bar } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";

class ChartsBar extends React.Component {
    state = {
        dataBar: {
            labels: ["React"],
            datasets: [
                {
                    label: "0 - 10",
                    data: [0, 10],
                    backgroundColor: ["rgba(0, 0, 0, 0.1)"],
                }
            ]
        },
        barChartOptions: {
            responsive: false,
            maintainAspectRatio: false,
            scales: {
                xAxes: [
                    {
                        barPercentage: 1,
                        gridLines: {
                            display: false,
                            color: "rgba(0, 0, 0, 0.1)"
                        }
                    }
                ],
                yAxes: [
                    {
                        gridLines: {
                            display: false,
                            color: "rgba(0, 0, 0, 0.1)"
                        },
                        ticks: {
                            beginAtZero: true
                        }
                    }
                ]
            }
        }
    };

    componentWillMount() {

        let data = this.props.data;
        let bg = data.map(t => {
            if(t.punctuation > 10) {
                return "rgba(207, 181, 59,0.4)";
            } else if(t.punctuation > 6) {
                return "rgba(71, 225, 167,0.4)";
            } else if(t.punctuation > 3) {
                return "rgba(113, 205, 205,0.4)";
            } else {
                return "rgba(209, 132, 163,0.4)";
            }
        });

        this.setState({
            dataBar: {
                labels: this.props.data.map(t => { return  t.tech }),
                datasets: [{
                    label: "0 - 10",
                    data: this.props.data.map(t => { return  t.punctuation }),
                    backgroundColor: bg,
                }]
            }
        });

    }

    render() {
        return (
            <MDBContainer>
                <h5 className="mt-2">knowledge level</h5>
                <Bar data={this.state.dataBar} options={this.state.barChartOptions} />
            </MDBContainer>
        );
    }
}

export default ChartsBar;
