import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Loading from '../../components/loading';
import api from '../../services/api';
import SwitchButton from "../../components/SwitchButton";

export default function Dashboard({history}) {

    const userType = localStorage.getItem("user.role");
    const username = localStorage.getItem("user.username");
    if(userType === "dev" && username){
        history.push(`/dev/${username}`);
    }
    if(!userType || userType === "Dev"){
        history.push("/devs");
    }

    const [companies, setCompanies] = useState([{}]);
    const [table, setTable] = useState([{}]);
    const booking = [];
    const dev = {};

    function calculateAge(since) {
        var ageDifMs = Date.now() - new Date(since);
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    function convertDate(date) {
        if (date) {
            return date.slice(0,10);
        }
        return null
    }

    useEffect(() => {
        async function loadCompanies() {
            const user_id = localStorage.getItem('user');
            const response = await api.get('/spots', {
                headers: { user_id }
            });

            setCompanies(response.data.data);

            let resBooking = [];
            await api.get(`/spots/booking`, {})
                .then(response => {
                    resBooking = response.data.data;
                }).catch(error => {
                    console.log(error)
                    //logout();
                });

            for (let i = 0; i < resBooking.length; i++) {
                let id = resBooking[i]._id;
                let avatar = resBooking[i].dev.avatar;
                let name = resBooking[i].dev.user[0].name;
                let experience = calculateAge(resBooking[i].dev.dev_since) + " years +";
                let date_ini = convertDate(resBooking[i].date_ini);
                let date_end = convertDate(resBooking[i].date_end);
                let approved = resBooking[i].approved;

                dev[i] = {id, avatar, name, experience, date_ini, date_end, approved};
                booking.push({id, avatar, name, experience, date_ini, date_end, approved});
            }
            setTable(booking);
        }
        loadCompanies();

    }, []);

    return (

        <React.Fragment>

        <Loading />

        <section className="section">
            <div className="container">

                <div className="row right" style={{float: "right"}}>
                    <div className="col-12 text-center mt-4 pt-2">
                        <Link to="/company/create" className="btn btn-primary">
                            Create new company
                            <i className="mdi mdi-arrow-right"> </i>
                        </Link>
                    </div>
                </div>

                <div className="row">

                {companies.map( company => (

                    <div key={Math.random()} className="col-lg-4 col-md-6 mb-4 pb-2">
                        <div className="blog position-relative overflow-hidden shadow rounded">
                            <div className="position-relative">
                                <img src={company.image ? company.image.path : null} className="img-fluid rounded-top" alt=""/>
                                    <div className="overlay rounded-top bg-dark"> </div>
                            </div>
                            <div className="content p-4">
                                <h4><a href="#" className="title text-dark">{company.name} </a></h4>
                                <div className="post-meta mt-3">
                                    <Link to={`/company/${company.id}/update`} className="text-muted float-right readmore">
                                        Update
                                        <i className="mdi mdi-chevron-right"> </i>
                                    </Link>

                                    <ul className="list-unstyled mb-0">
                                        <li className="list-inline-item mr-2">
                                            <a href="#" className="text-muted like">
                                                {company.price ? `R$${company.price}/dia` : 'Free'}
                                            </a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block">
                                    <i className="mdi mdi-bank-outline"> </i>
                                     {company.address ? company.address.street_name : null}
                                </small>
                                <small className="text-light date">
                                    <i className="mdi mdi-city-variant"> </i>
                                     {company.address ? company.address.city : null}
                                </small>
                            </div>
                        </div>
                    </div>
                ))}
                </div>
            </div>

        </section>

            {table.length > 0 && (
            <section className="section">
                <div className="container">
                    <div className="row">

                        {/*<BookingTable booking={table}/>*/}

                        <div className="table-responsive">
                            <table className="table">
                                <thead>
                                <tr>
                                    <th>Thumb</th>
                                    <th>Name</th>
                                    <th>Experience</th>
                                    <th>Date ini</th>
                                    <th>Date end</th>
                                    <th>Approve</th>
                                </tr>
                                </thead>
                                <tbody>

                                { table.map( dev => (
                                    <tr key={Math.random()}>
                                        <td><img width="40px" src={dev.avatar} className="img-fluid rounded" alt=""/></td>
                                        <td>{dev.name}</td>
                                        <td>{dev.experience}</td>
                                        <td>{dev.date_ini}</td>
                                        <td>{dev.date_end}</td>
                                        <td>
                                            <SwitchButton checked={dev.approved} booking_id={dev.id}/>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </section>
            )}


        </React.Fragment>
    )
}
