import { Component } from 'react';
import smtp from '../services/smtp';

class SmtpService extends Component {
  /* https://smtpjs.com/ */
  /* SmtpJS.com - v2.0.1 */

  send = async (e, o, t, n, a, s, r, c) => {
    let d = Math.floor(1e6 * Math.random() + 1);
    let i = `From=${e}&to=${o.email}&Subject=${t.subject}&Body=${encodeURIComponent(n)}`;

    if (!a) {
      i += `&Host=${a}&Username=${s}&Password=${r}&Action=Send`;
    } else {
      i += `&SecureToken=${a}&Action=SendFromStored`;
      c = a.callback;
    }
    i += '&cachebuster=' + d;

    return await smtp.post(`?${i}`);
  };

  sendAll = async (a) => {
    return new Promise(function(n, e) {
      a.nocache = Math.floor(1e6 * Math.random() + 1);
      a.Action = "Send";
      var t = JSON.stringify(a);
      console.log(encodeURIComponent(JSON.stringify(a)));
      smtp.post("",{ params: t })
    })
  }

}

export default SmtpService;
