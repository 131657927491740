import React, { useState } from 'react';
import SmtpService from "../../services/SmtpService";

export default function Contact({ history }) {

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');

    async function hadlesubmit(e){
        e.preventDefault();
      let sender = new SmtpService();

      console.log(email);

        if (!name || !email || !subject || !message) {
            setError("Preencha todos os campos!");
        } else {
            try {
              sender.sendAll({
                SecureToken : "bc4ed53d-f43c-4ec8-a968-836584ad747d",
                To : {email},
                From : "contact@codenroll.app",
                Subject : {subject},
                Body : {message}
               }).then(
                message => setError(message.data)
              );
            } catch (err) {
                setError(err);
            }
        }

    }

    return (

        <div className="container mt-100 mt-60">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1">
                    <div className="pt-5 pb-5 p-4 bg-light shadow rounded">
                        <h4>Get In Touch !</h4>
                        <div className="custom-form mt-4">
                          {error &&
                          <div className="text-center">
                            <h6 className="mb-5">{error}</h6>
                          </div>
                          }
                            <form name="contact-form" id="contact-form" onSubmit={hadlesubmit}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label>Your Name <span className="text-danger">*</span></label>
                                            <i className="mdi mdi-account ml-3 icons"> </i>
                                            <input
                                              name="name"
                                              id="name"
                                              type="text"
                                              className="form-control pl-5"
                                              placeholder="First Name :"
                                              kl_vkbd_parsed="true"
                                              onChange={event => setName(event.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label>Your Email <span className="text-danger">*</span></label>
                                            <i className="mdi mdi-email ml-3 icons"> </i>
                                            <input
                                              name="email"
                                              id="email"
                                              type="email"
                                              className="form-control pl-5"
                                              placeholder="Your email :"
                                              kl_vkbd_parsed="true"
                                              onChange={event => setEmail(event.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group position-relative">
                                            <label>Subject</label>
                                            <i className="mdi mdi-book ml-3 icons"> </i>
                                            <input
                                              name="subject"
                                              id="subject"
                                              className="form-control pl-5"
                                              placeholder="Your subject :"
                                              kl_vkbd_parsed="true"
                                              onChange={event => setSubject(event.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group position-relative">
                                            <label>Comments</label>
                                            <i className="mdi mdi-comment-text-outline ml-3 icons"> </i>
                                            <textarea
                                              name="comments"
                                              id="comments"
                                              rows="4"
                                              className="form-control pl-5"
                                              placeholder="Your Message :"
                                              onChange={event => setMessage(event.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-sm-12 text-center">
                                        <input type="submit" id="submit" name="send" className="submitBnt btn btn-primary btn-block" value="Send Message" kl_vkbd_parsed="true"/>
                                            <div id="simple-msg"> </div>
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>
                </div>


                <div className="col-lg-7 col-md-6 order-1 order-md-2">
                    <img src="assets/images/contact.png" className="img-fluid" alt=""/>
                </div>

            </div>

        </div>
    )
}

