import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import PrivateRoute from "./PrivateRoute";

import Login from './pages/User/Login';
import Logon from './pages/User/Logon';
import { logout } from "./services/auth";
import Home from './pages/Home';
import Dashboard from "./pages/Dashboard/company";
import CreateCompany from './pages/Company/create';
import UpdateCompany from './pages/Company/update';
import ListCompanies from './pages/Company/list'
import Booking from "./pages/Booking";
import ListDevs from "./pages/Dev/list";
import Dev from "./pages/Dev";
import Contact from "./pages/Contact";
import Privacy from "./pages/GDPR/privacy";
import Terms from "./pages/GDPR/terms";
import Services from "./pages/Others/services";
import About from "./pages/Others/about";
import NotFound from "./components/NotFound";
import LoginGithub from "./pages/Login/github/login";
import CallbackGithub from "./pages/Login/github/callback"
import FAQ from "./pages/Others/faq";


export default function Routes() {
    return (
        <BrowserRouter>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/login" component={Login}/>
                <Route path="/logon" component={Logon}/>
                <Route path="/logout" component={logout} />
                <Route path="/contact" component={Contact}/>
                <Route path="/companies" component={ListCompanies}/>
                <Route path="/devs" component={ListDevs}/>
                <Route path="/dev/:username" component={Dev}/>
                <Route path="/auth/github" component={LoginGithub}/>

                <PrivateRoute path="/company/create" component={CreateCompany}/>
                <PrivateRoute path="/company/:id/update" component={UpdateCompany}/>
                <PrivateRoute path="/company/:id/booking" component={Booking}/>
                <PrivateRoute path="/dashboard" component={Dashboard}/>

                <Route path="/privacy"  component={Privacy}/>
                <Route path="/terms"  component={Terms}/>
                <Route path="/about"  component={About}/>
                <Route path="/services"  component={Services}/>
                <Route path="/faq"  component={FAQ}/>
                <Route path="" component={NotFound} />
            </Switch>
        </BrowserRouter>
    )
}
