import React, { useState, useMemo } from 'react';
import Loading from '../../components/loading';
import api from '../../services/api';
import { getToken, logout } from '../../services/auth';

import camera from '../../assets/images/camera.svg';
import './index.css';

export default function CreateCompany( { history } ) {

    const [spot, setSpot] = useState('');
    const [techs, setTechs] = useState('');
    const [days, setDays] = useState('');
    const [thumbnail, setThumbnail] = useState(null);
    const [about, setAbout] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [positions, setPositions] = useState('');
    const [error, setError] = useState('');

    const preview = useMemo(()=> {
        return thumbnail ? URL.createObjectURL(thumbnail) : null;
    },[thumbnail]);

    async function handleSubmit(e) {
        e.preventDefault();

        const data = new FormData();

        data.append('thumbnail', thumbnail);
        data.append('techs', techs);
        data.append('price', 0);
        data.append('days', days);
        data.append('name', spot);
        data.append('address', address);
        data.append('city', city);
        data.append('zip_code', zipcode);
        data.append('positions', positions);
        data.append('about', about);

        await api.post('/spots', data)
          .then(response => {
            if (response.status === 200) {
              history.push('/dashboard');
            }
            setError(response.error);
          })
          .catch(error => {
            setError(e.response.data ? e.response.data.error : e.message);
          });

    }

    return (
<>
        <section className="section">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-10 col-md-7">
                        <div className="custom-form">
                          {error &&
                            <div className="text-center">
                              <h6 className="mb-5">{error}</h6>
                            </div>
                          }
                            <form onSubmit={handleSubmit} className="rounded shadow p-4">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group position-relative">

                                            <label
                                                id="thumbnail"
                                                style={{ backgroundImage: `url(${preview})`}}
                                                className={thumbnail ? 'has-thumbnail' : ''}>
                                                <input type="file" onChange={event => setThumbnail(event.target.files[0])}/>
                                                <img src={camera} alt="Select"/>
                                            </label>

                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Company :</label>
                                            <i className="mdi mdi-domain ml-3 icons"></i>

                                            <input
                                                id="company"
                                                className="form-control pl-5"
                                                placeholder="Company Name"
                                                value={spot}
                                                onChange={event => setSpot(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Address :</label>
                                            <i className="mdi mdi-bank-outline ml-3 icons"></i>

                                            <input
                                                id="address"
                                                className="form-control pl-5"
                                                placeholder="Avenida Paulista, 222 4º andar"
                                                value={address}
                                                onChange={event => setAddress(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>City :</label>
                                            <i className="mdi mdi-city-variant ml-3 icons"> </i>
                                            <input
                                                id="city"
                                                className="form-control pl-5"
                                                placeholder="São Paulo"
                                                value={city}
                                                onChange={event => setCity(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Zip code :</label>
                                            <i className="mdi mdi-home-map-marker ml-3 icons"></i>
                                            <input
                                                id="zipcode"
                                                className="form-control pl-5"
                                                placeholder="05040000"
                                                value={zipcode}
                                                onChange={event => setZipcode(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Positions :</label>
                                            <i className="mdi mdi-dev-to ml-3 icons"> </i>
                                            <input
                                                id="positions"
                                                className="form-control pl-5"
                                                type="number"
                                                placeholder="01"
                                                value={positions}
                                                onChange={event => setPositions(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Days :<span className="text-info"> (how many days)</span></label>
                                            <i className="mdi mdi-calendar ml-3 icons"> </i>
                                            <input
                                                className="form-control pl-5"
                                                id="days"
                                                type="number"
                                                placeholder="01"
                                                value={days}
                                                onChange={event => setDays(event.target.value)}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="form-group position-relative">
                                            <label><span className="text-danger">* </span>Techs :<span className="text-info">* (comma separated)</span></label>
                                            <i className="mdi mdi-code-braces ml-3 icons"> </i>

                                            <input
                                                id="techs"
                                                className="form-control pl-5"
                                                placeholder="Technologies and methodologies used in the company"
                                                value={techs}
                                                onChange={event => setTechs(event.target.value)}
                                                required="required"
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group position-relative">
                                            <label>Description : </label>
                                            <i className="mdi mdi-comment-text-outline ml-3 icons"> </i>
                                            <textarea
                                                id="about"
                                                placeholder="We are a community of developers who have come together to generate knowledge and career possibilities."
                                                onChange={event => setAbout(event.target.value)}
                                                name="about"
                                                rows="4"
                                                className="form-control pl-5"
                                            >

                                            </textarea>
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <div className="custom-control custom-checkbox">
                                                <input required="required" type="checkbox" className="custom-control-input"
                                                       id="customCheck1"/>
                                                    <label className="custom-control-label" htmlFor="customCheck1">
                                                        I Accept <a href="#" className="text-primary">Terms And Condition</a>
                                                    </label>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-sm-12">
                                        <input type="submit" id="submit" name="send"
                                               className="submitBnt btn btn-primary" value="Apply Now" />
                                    </div>

                                </div>

                            </form>

                        </div>

                    </div>
                </div>
            </div>

        </section>
</>
    )
}
