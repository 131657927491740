import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Loading from '../../components/loading';
import api from '../../services/api';
import ChartsBar from '../../components/chartBar';
import { RepoList } from './styles';
import Likes from "../../components/likes";

export default function Dev(props) {

    const username = props.match.params.username;
    const [dev, setDev] = useState([{}]);
    const [repos, setRepos] = useState([{}]);

    function calculateAge(since) {
        var ageDifMs = Date.now() - new Date(since);
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    function createString(arr, key) {
        return arr.map(function (obj) {
            return "'" + obj[key] + "'";
        }).join(', ');
    }

    useEffect(() => {
        async function loadDev() {
            const resDev = await api.get(`/devs/${username}`);
            setDev(resDev.data.data);

          const reposGit = await axios.get(`https://api.github.com/users/${username}/repos`, {
              params: { sort: 'updated', per_page: 5 },
            });
          setRepos(reposGit.data);

            // https://api.github.com/users/fabiovalencio/repos
        }
        loadDev();
    }, []);

    return (

        <React.Fragment>

            <Loading />

            <section className="bg-half bg-light">

                <div className="home-center">
                    <div className="home-desc-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 text-center">
                                    <div className="page-next-level">
                                        <h4 className="title"> { dev.user ? dev.user.name : null} </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <section style={{padding: "0"}} className="section">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 mt-4 pt-2">

                            <div className="row">

                              <div className="col-md-4 mt-4 pt-2">
                                <img width="200px" src={ dev.avatar} className="img-fluid rounded" alt=""/>
                                <br/>
                                {dev.techs &&
                                <ChartsBar data={dev.techs}/>
                                }

                              </div>

                              <div className="col-md-8 mt-4 pt-2">
                                  <div className="work-details rounded bg-light p-4">

                                      <ul className="list-unstyled mb-0">
                                          <li className="mt-3">
                                              <b>Name :</b>
                                              <span>{ dev.user ? dev.user.name : null}</span>
                                          </li>
                                          <li className="mt-3">
                                              <b>Experience :</b>
                                              <span> {calculateAge(dev.dev_since)} Year +</span>
                                          </li>
                                          <li className="mt-3">
                                              <b>Provider :</b>
                                              <span>
                                                  <a href={dev.user ? "https://"+dev.provider+".com/"+dev.nickname : "#"} target="_blank">
                                                  {dev.user ? dev.provider+".com/"+dev.nickname : null}
                                                  </a>
                                              </span>
                                          </li>
                                          <li className="mt-3">
                                              <b>Location :</b>
                                              <span>{dev.city}</span>
                                          </li>
                                      </ul>

                                  </div>
                                <br/>
                                <div className="work-details rounded bg-light p-4" role="alert">
                                  <p>{dev.bio}</p>
                                </div>

                              </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

          <section style={{padding: "0"}} className="section">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-10 mt-4 pt-2">

                  <div className="row">

                    <p>The user's last five public repositories</p>
                    <RepoList>
                      {repos.map(repo => (
                        <li key={String(repo.id)}>
                          <div>
                            <strong>
                              <a href={repo.html_url} target="_blank">{repo.name}</a>
                              {repo.language && <span>{repo.language}</span>}
                            </strong>
                            <p>{repo.description}</p>
                            <p>{repo.created_at}</p>
                          </div>
                        </li>
                      ))}
                    </RepoList>

                  </div>

                </div>
              </div>
            </div>
          </section>
            <br/>



        </React.Fragment>
    )
}
