import React from 'react';

const Companies = () => {
    return (

        <section className="section bg-light">
            <div className="container mt-100 mt-60">
                <div className="row justify-content-center">
                    <div className="col-12 text-center">
                        <div className="section-title mb-4 pb-2">
                            <h4 className="title mb-4">Latest News & Events</h4>

                        </div>
                    </div>

                </div>


                <div className="row">
                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="blog position-relative overflow-hidden shadow rounded">
                            <div className="position-relative">
                                <img src="https://image.cnbcfm.com/api/v1/image/106163167-1570127937840gettyimages-1023150598.jpg?v=1570127996&w=1400&h=950" className="img-fluid rounded-top" alt=""/>
                                <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="content p-4">
                                <h4><a target="_blank" href="https://www.cnbc.com/2019/10/07/comparably-top-100-companies-with-best-compensation-2019.html" className="title text-dark">This tech giant ranks No. 1 as the company with the best compensation</a></h4>
                                <div className="post-meta mt-3">
                                    <a target="_blank" href="https://www.cnbc.com/2019/10/07/comparably-top-100-companies-with-best-compensation-2019.html" className="text-muted float-right readmore">Read More <i className="mdi mdi-chevron-right"></i></a>

                                </div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><i className="mdi mdi-account"></i> cnbc.com </small>
                                <small className="text-light date"><i className="mdi mdi-calendar-check"></i> Oct 7 2019</small>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="blog position-relative overflow-hidden shadow rounded">
                            <div className="position-relative">
                                <img src="https://image.cnbcfm.com/api/v1/image/105666742-1546981039763workingatdesk.jpg?v=1546981104&w=1400&h=950" className="img-fluid rounded-top" alt=""/>
                                <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="content p-4">
                                <h4><a target="_blank" href="https://www.cnbc.com/2019/01/08/the-25-most-in-demand-technical-skills-of-2019-according-to-linkedin-.html" className="title text-dark">The 25 most in-demand technical skills of 2019</a></h4>
                                <div className="post-meta mt-3">
                                    <a target="_blank" href="https://www.cnbc.com/2019/01/08/the-25-most-in-demand-technical-skills-of-2019-according-to-linkedin-.html" className="text-muted float-right readmore">Read More <i className="mdi mdi-chevron-right"></i></a>

                                </div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><i className="mdi mdi-account"></i> cnbc.com</small>
                                <small className="text-light date"><i className="mdi mdi-calendar-check"></i> Jan 9 2019</small>
                            </div>
                        </div>
                    </div>


                    <div className="col-lg-4 col-md-6 mt-4 pt-2">
                        <div className="blog position-relative overflow-hidden shadow rounded">
                            <div className="position-relative">
                                <img src="https://static01.nyt.com/images/2019/09/11/business/11techusing1/merlin_159520215_099fe9e5-6f2e-4257-aeab-7d718acc6d00-jumbo.jpg?quality=90&auto=webp" className="img-fluid rounded-top" alt=""/>
                                <div className="overlay rounded-top bg-dark"></div>
                            </div>
                            <div className="content p-4">
                                <h4><a target="_blank" href="https://www.nytimes.com/2019/09/11/technology/personaltech/staying-on-top-of-techs-changing-story.html" className="title text-dark">Staying on Top of Tech’s Changing Story</a></h4>
                                <div className="post-meta mt-3">
                                    <a target="_blank" href="https://www.nytimes.com/2019/09/11/technology/personaltech/staying-on-top-of-techs-changing-story.html" className="text-muted float-right readmore">Read  More <i className="mdi mdi-chevron-right"></i></a>

                                </div>
                            </div>
                            <div className="author">
                                <small className="text-light user d-block"><i className="mdi mdi-account"></i> nytimes.com</small>
                                <small className="text-light date"><i className="mdi mdi-calendar-check"></i> Sept 11 2019</small>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
};

export default Companies;
