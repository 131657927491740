import React from 'react';
import Jquery from 'jquery';

class Loading extends React.Component {

    constructor(props){
        super(props);
        this.state = { isLoading: true }
    }

    componentDidMount() {

        Jquery('#status').fadeOut();
        Jquery('#preloader').delay(350).fadeOut('slow');
        Jquery('body').delay(350).css({
            'overflow': 'visible'
        });
        this.setState({isLoading: false});
    }

    render(){

        return(
            <div id="preloader">
                <div id="status">
                    <div className="spinner">
                        <div className="double-bounce1"></div>
                        <div className="double-bounce2"></div>
                    </div>
                </div>
            </div>
        );

    }


}

export default Loading;
