import React from 'react';

const NotFound = () => {
    return (
        <section className="bg-home">
            <div className="home-center">
                <div className="home-desc-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-8 col-md-12 text-center">
                                <img src="/assets/images/404.gif" className="img-fluid" alt=""/>
                                    <div className="text-uppercase mt-4 display-3">Oh ! no</div>
                                    <div className="text-capitalize text-dark mb-4 error-page">Page Not Found</div>
                                    <p className="text-muted para-desc mx-auto">
                                        Meet the best companies with the best teams using the best development tools.<br/>
                                        Meet the best developers and hire talent.
                                    </p>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 text-center">
                                <a href="/" className="btn btn-outline-primary mt-4">Go Back</a>
                                <a href="/" className="btn btn-primary mt-4 ml-2">Go To Home</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
)
};

export default NotFound;
