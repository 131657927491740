import React, { useState } from 'react';
import api from "../../../services/api";
import {login, isAuthenticated} from "../../../services/auth";

import GitHubLogin from 'react-github-login';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-social/bootstrap-social.css';
import '../../Login/github/login.css';

export default function Login({ history }) {

    if(isAuthenticated()) {
       history.push("/dashboard");
    }
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    async function gitLogin(response) {
		console.log(response);
        try {
            await api.post('/devs/github', { 'code': response.code })
                .then(async result => {
                    if (result.status === 200) {
                        localStorage.setItem("user.name", result.data.user.name);
                        localStorage.setItem("user.email", result.data.user.email);
                        localStorage.setItem("user.id", result.data.user.id);
                        localStorage.setItem("user.role", result.data.user.role);
                        localStorage.setItem("user.username", result.data.user.username);
                        login(result.data.token);
                        history.push(`/dev/${result.data.user.username}`);
                    }
                }).catch(e => {
                    if(e.response.data){
                        console.log(e.response.data);
                        alert(e.response.data.error);
                    }
                    console.log(e.response);
                    // setError(true);
                });
        } catch (e) {
            alert(e.message);
        }
    }

    async function hadlesubmit(e){
        e.preventDefault();

        if (!email || !password) {
            setError("Preencha username e senha para continuar!");
        } else {
            try {
                await api.post('/session', { email,  password})
                    .then(result => {
                        if (result.status === 200) {
                            localStorage.setItem("user.name", result.data.user.name);
                            localStorage.setItem("user.email", result.data.user.email);
                            localStorage.setItem("user.id", result.data.user.id);
                            localStorage.setItem("user.role", result.data.user.role);
                            login(result.data.token);
                            history.push("/dashboard");
                        } else {
                            setError(true);
                        }
                    }).catch(e => {
                        console.log(e.message);
                        setError(true);
                    });
            } catch (err) {
                console.log(err.message);
                setError("Houve um problema com o login, verifique suas credenciais.");
            }
        }
    }

    return (
        <>

            <section className="bg-home">
                <div className="home-center">
                    <div className="home-desc-center">
                        <div className="container">
                            <div className="row align-items-center">
                              <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 hg-440">

                                <div className="login-page bg-white shadow rounded p-4 hg-100">
                                  <div className="row">

                                    <div className="col-lg-12 mt-4 text-center">
                                      <div className="text-center">
                                        <h4 className="mb-4">Developer</h4>
                                      </div>
                                      <ul className="list-unstyled social-icon mb-0 mt-3">
                                        <li className="list-inline-item">
                                          <GitHubLogin
                                            clientId="1da307652bf7a002eac4"
                                            redirectUri="https://codenroll.app/auth/github/callback"
                                            onSuccess={gitLogin}
                                            className={" btn btn-social btn-github fa fa-github"}
                                            buttonText={" Sign in with Github"}
                                          />
                                        </li>

                                      </ul>

                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">

                                    <div className="login-page bg-white shadow rounded p-4">

                                        {error &&
                                            <div className="text-center">
                                                <h6 className="mb-5">{error}</h6>
                                            </div>
                                        }


                                        <form className="login-form" onSubmit={hadlesubmit}>
                                            <div className="row">

                                                <div className="col-lg-12 mt-4 text-center">
                                                    <div className="text-center">
                                                        <h4 className="mb-4">Company</h4>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12">

                                                    <div className="form-group position-relative">
                                                        <label>Your Username <span className="text-danger">*</span></label>
                                                        <i className="mdi mdi-account ml-3 icons"></i>

                                                        <input
                                                            id="username"
                                                            className="form-control pl-5"
                                                            type="text"
                                                            placeholder="Username"
                                                            required=""
                                                            value={email}
                                                            onChange={event => setEmail(event.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="form-group position-relative">
                                                        <label>Password <span className="text-danger">*</span></label>
                                                        <i className="mdi mdi-key ml-3 icons"></i>

                                                        <input
                                                            id="pass"
                                                            className="form-control pl-5"
                                                            type="password"
                                                            placeholder="Escolha a sua senha"
                                                            required=""
                                                            value={password}
                                                            onChange={event => setPassword(event.target.value)}
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    {/*<p className="float-right forgot-pass">*/}
                                                    {/*    <a href="page_recovery_passward.html"*/}
                                                    {/*       className="text-dark font-weight-bold">*/}
                                                    {/*        Forgot password ?</a>*/}
                                                    {/*</p>*/}
                                                    {/*<div className="form-group">*/}
                                                    {/*    <div className="custom-control custom-checkbox">*/}
                                                    {/*        <input type="checkbox" className="custom-control-input"*/}
                                                    {/*               id="customCheck1"/>*/}
                                                    {/*        <label className="custom-control-label" htmlFor="customCheck1">*/}
                                                    {/*            Remember me</label>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                </div>
                                                <div className="col-lg-12 mb-0">
                                                    <button type="submit" className="btn btn-primary w-100">Sign in</button>
                                                </div>

                                                <div className="col-12 text-center">
                                                    <p className="mb-0 mt-3"><small className="text-dark mr-2">Don't have an account
                                                        ?</small> <a href="/logon" className="text-dark font-weight-bold">Sign
                                                        Up</a></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>
            </section>

        </>
    )
}
