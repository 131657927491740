import React, { useEffect, useState } from 'react';
import Loading from '../../components/loading';
import api from '../../services/api';
import Likes from "../../components/likes";
import CalendarDateRange from "../../components/calendar";
import { format } from 'date-fns-tz';

let getDaysArray = function(start, end) {
    for(var arr=[],dt=start; dt<=end; dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
    }
    return arr;
};

export default function Booking({ history, match}) {

    const id = match.params.id;
    const [company, setCompany] = useState([{}]);
    const [booking, setBooking] = useState([{}]);
    const [date, setDate] = useState({});
    const [user, setUser] = useState(localStorage.getItem('user.id'));
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);

    let range = [];

    async function getData(val){
        await setDate(val);
    }

    function closeAlert() {
        setError(null);
        setMessage(null);
    }

    async function hadlesubmit(e){
        e.preventDefault();
        let chooseDate = date.selection;
        // let date_ini = chooseDate.startDate.toISOString().slice(0,10);
        // let date_end = chooseDate.endDate.toISOString().slice(0,10);

        try {
            await api.post(`/spots/${company.id}/booking`, {
                "date_ini": chooseDate.startDate,
                "date_end": chooseDate.endDate,
            }).then(response => {
                setMessage("Your reservation has been made, please wait for confirmation.");

            }).catch(error => {
                setError("Something went wrong: " + error.message);
                if (error.response) {
                    setError("Something went wrong: " + error.response.data.data);
                }
            });

        } catch (err) {
            setError("There was a problem with your login, check your credentials." + err.message);
        }
        window.scrollTo(0, 0);
    }

    useEffect(() => {
        async function loadCompany() {

            let reserved = [];
            let positions = [];
            let resBooking = [];
            let resDevs = [];

            await api.get(`/spots/${id}`)
                .then(response => {
                    resDevs = response.data.data;
                    positions = resDevs.positions;
                    setCompany(resDevs);
                }).catch(error => {
                    setError(error.response.data ? error.response.data.error : error.message);
                });

            await api.get(`/spots/${id}/booking`, {})
                .then(response => {
                    resBooking = response.data.data;
                    setBooking(resBooking);
                }).catch(error => {
                    setError(error.response.data ? error.response.data.error : error.message);
                });

            //map bookingDate and create an array with all reserved dates
            //create array of date range between start and end date
            for (let i = 0; i < resBooking.length; i++) {
                reserved.push(getDaysArray(new Date(resBooking[i].date_ini), new Date(resBooking[i].date_end)));
            }

            let counts = {};
            //creating a list of the amount of each reserved date
            for (let i = 0; i < reserved.length; i++) {
                let date = reserved[i];
                for(let a = 0; a < date.length; a++) {
                    let dt = format(date[a], 'yyyy-MM-dd');
                    counts[dt] = counts[dt] ? counts[dt] + 1 : 1;
                }
            }
            console.log(counts);
            //if counts is greater than position, blocks date
            for (let i in counts) {
                if(counts[i] >= positions){
                    range.push(new Date(i+" 00:00:00"));
                }
            }
            window.scrollTo(0, 0);
        }

        loadCompany();

    }, [id]);

    return (

        <React.Fragment>

            <Loading />

            <section className="bg-half bg-light">

                <div className="home-center">
                    <div className="home-desc-center">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-lg-12 text-center">
                                    <div className="page-next-level">
                                        <h4 className="title"> Booking </h4>
                                        <ul className="list-unstyled mt-4">
                                            <li className="list-inline-item h6 user text-muted mr-2"><span
                                                className="text-dark">{company.name}</span>
                                            </li>
                                            {company.address && (
                                            <li className="list-inline-item h6 date text-muted">
                                                {company.address.street_name}, {company.address.street_number} - {company.address.city}
                                            </li>
                                            )}
                                        </ul>

                                        {message && (
                                            <ul className="page-next d-inline-block bg-white shadow p-2 pl-4 pr-4 rounded mb-0">
                                                <div className="alert alert-success alert-dismissible fade show" role="alert">
                                                    <strong>Well done!</strong>
                                                    {message}
                                                    <button type="button" className="close" data-dismiss="alert"  aria-label="Close">
                                                        <span onClick={closeAlert} aria-hidden="true"> × </span>
                                                    </button>
                                                </div>
                                            </ul>
                                        )}

                                        {error && (
                                            <ul className="page-next d-inline-block bg-white shadow p-2 pl-4 pr-4 rounded mb-0">
                                                <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                    <strong>Oh no! </strong>
                                                   {error}
                                                    <button type="button" className="close" data-dismiss="alert"  aria-label="Close">
                                                        <span onClick={closeAlert} aria-hidden="true"> × </span>
                                                    </button>
                                                </div>
                                            </ul>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="section">
                <div className="container">
                    <div className="row">

                        <div className="col-lg-8 col-md-7">
                            <div className="mr-lg-3">
                                <div className=" position-relative overflow-hidden shadow rounded">
                                    <div className="position-relative">
                                        <img src={company.image ? company.image.path : '#'} className="img-fluid rounded-top" alt=""/>
                                    </div>
                                    <div className="content p-4">
                                        <h6>
                                            <i className="mdi mdi-tag text-primary mr-1"> </i>
                                            <a href="#" className="text-primary">{company.name}</a>
                                        </h6>
                                        <p className="text-muted mt-3">{company.about}</p>

                                        {company.likes && (
                                            <Likes
                                                likes={company.likes ? company.likes.length : 0}
                                                spot={company.id}
                                                updated={company.likes ? company.likes.some(({user_id}) => user_id === user) : false}
                                                booking={false}
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="p-4 shadow rounded mt-4 pt-2">
                                    <h4 className="page-title pb-3">Max days booking : {company.days}</h4>
                                   <CalendarDateRange
                                       maxDays={company.days}
                                       disabledDates={range}
                                       sendData={getData}
                                   />
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-5 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="sidebar mt-sm-30 p-4 rounded shadow">

                                <div className="widget mb-4 pb-2">
                                    <div className="map map-gray">
                                        {company.address && (
                                        <iframe title="google"
                                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBUSIc3-gMyWdcGvzzXuAKNK8SyJmlWucw&q=${company.name}+${company.address.street_name}+${company.address.street_number}+${company.address.city}`}
                                            style={{border:"0"}} className="rounded" allowFullScreen="">

                                        </iframe>
                                        )}
                                    </div>
                                </div>

                                <div className="widget mb-4 pb-2">
                                    <h4 className="widget-title">Techs</h4>
                                    <div className="tagcloud mt-4">

                                        {company.techs &&
                                        company.techs.map( t => (
                                            <a key={Math.random()} href="#" className="rounded">{t.tech}</a>
                                        ))}
                                    </div>
                                </div>

                                {date.selection && (

                                <div>

                                    <div className="login-page bg-white shadow rounded p-4">

                                        <form className="login-form" onSubmit={hadlesubmit}>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group position-relative">
                                                        <label>Initial date </label>
                                                        <i className="mdi mdi-calendar-range ml-3 icons"> </i>

                                                        <input
                                                            id="date_ini"
                                                            className="form-control pl-5"
                                                            type="text"
                                                            disabled="disabled"
                                                            value={date.selection.startDate.toLocaleDateString() }

                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12">
                                                    <div className="form-group position-relative">
                                                        <label>Final date </label>
                                                        <i className="mdi mdi-calendar-range ml-3 icons"> </i>

                                                        <input
                                                            id="date_end"
                                                            className="form-control pl-5"
                                                            type="text"
                                                            disabled="disabled"
                                                            value={date.selection.endDate.toLocaleDateString() }
                                                        />
                                                    </div>
                                                </div>

                                                <div className="col-lg-12 mb-0">
                                                    <button type="submit" className="btn btn-primary w-100">Reserve</button>
                                                </div>

                                            </div>
                                        </form>
                                    </div>

                                </div>

                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </React.Fragment>
    )
}
