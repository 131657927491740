import React from 'react';
import api from "../services/api";

class Likes extends React.Component {

    constructor(props){

        super(props);
        this.state ={
            likes: this.props.likes,
            updated: this.props.updated,
            spot: this.props.spot,
            booking: this.props.booking
        };
        this.updateLikes = this.updateLikes.bind(this);
    }

    async updateLikes() {

        if(!this.state.updated) {
            await api.post(`/spots/${this.state.spot}/likes`);
            this.setState((prevState, props) => {
                return {
                    likes: prevState.likes + 1,
                    updated: true
                };
            });
        } else {
            await api.delete(`/spots/${this.state.spot}/likes`);
            this.setState((prevState, props) => {
                return {
                    likes: prevState.likes - 1,
                    updated: false
                };
            });
        }


    }

    render(){

        return(
            <div className="post-meta mt-3">
                {this.state.booking && (
                    <a href={`/company/${this.state.spot}/booking`} className="text-muted float-right readmore">
                        Booking <i className="mdi mdi-chevron-right"> </i>
                    </a>
                )}

                <ul className="list-unstyled mb-0">
                    <li className="list-inline-item mr-2">
                        <a onClick={this.updateLikes} className="text-muted like">
                            {
                                this.state.updated ? (
                                <i className="mdi mdi-heart mr-1"> </i>
                                ) : (
                                <i className="mdi mdi-heart-outline mr-1"> </i>
                                )}
                                {this.state.likes}
                        </a>
                    </li>

                </ul>
            </div>
        );
    }
}

export default Likes;
