import React from 'react';
import ReactDOM from 'react-dom';
import GitHubLogin from 'react-github-login';
import {Redirect} from "react-router";

import api from "../../../services/api";
import {login, isAuthenticated} from "../../../services/auth";

class CallbackGithub extends React.Component {
    state = {
        user: null
    }
    componentDidMount () {
        const { code } = this.props.match.params;

        fetch(`https://github.com/login/oauth/access_token?client_id=1da307652bf7a002eac4&client_secret=a1deda96b8e630c2f8cd5c2c6d84582a457ff2e1&code=${code}`)
            .then((user) => {
                this.setState(() => ({ user }))
            })
    }
    render() {
        return (
            <div> {this.state.user} </div>
                )

    }
}

export default CallbackGithub
