import React from 'react';
import Loading from "../../components/loading";

class About extends React.Component {

    render(){

        return(

            <React.Fragment>

                <Loading />

                <section className="bg-half bg-light">
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 text-center">
                                        <div className="page-next-level">
                                            <h4 className="title"> About Us </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row align-items-center">
                            {/*<div className="col-lg-5 col-md-5 mt-4 pt-2 mt-sm-0 pt-sm-0">*/}
                            {/*    <div className="position-relative">*/}
                            {/*        <img src="images/about.jpg" className="rounded img-fluid mx-auto d-block" alt=""/>*/}
                            {/*            <div className="play-icon">*/}
                            {/*                <a href="http://vimeo.com/287684225" className="play-btn video-play-icon">*/}
                            {/*                    <i className="mdi mdi-play text-primary rounded-pill bg-white shadow"></i>*/}
                            {/*                </a>*/}
                            {/*            </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}

                            <div className="col-lg-10 col-md-10 mt-4 pt-2 mt-sm-0 pt-sm-0">
                                <div className="section-title ml-lg-4">
                                    <h4 className="title mb-4">Our Story</h4>
                                    <p className="text-muted">
                                      <span className="text-primary font-weight-bold">Codenroll </span>
                                      was created to bring companies and developers together in a friendly and safe environment.</p>
                                      <p className="text-muted">As developers, we know that a collaborative work environment makes all the difference, and as a company, we know that engaging with the best developers is key to success.</p>
                                    <p className="text-muted">
                                      Bringing these two worlds together, we have created a way to evaluate a developer's skills through public projects on a version-controlled source-hosting platform. We can calculate on a scale of 0 to 10 each of the programming languages that developers have used to create projects.
                                    </p>
                                  <p className="text-muted">
                                    But that's not all. We also create a bridge from the developer to a company: the IT staff and the technologies they use allowing everyone to assess any mutual added value. With our test drives, we reduce false-positives and increase the odds of a successful fit for company and developer alike.
                                  </p>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div className="container mt-100 mt-60">
                        <div className="row justify-content-center">
                            <div className="col-12 text-center">
                                <div className="section-title mb-4 pb-2">
                                    <h4 className="main-title mb-4">Key Features</h4>

                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="key-feature d-flex p-3 rounded shadow bg-white">
                                    <div className="icon text-center rounded-pill mr-3">
                                        <i className="mdi mdi-responsive text-primary"></i>
                                    </div>
                                    <div className="content mt-2">
                                        <h4 className="title mb-0">Developer experience </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="key-feature d-flex p-3 rounded shadow bg-white">
                                    <div className="icon text-center rounded-pill mr-3">
                                        <i className="mdi mdi-internet-explorer text-primary"></i>
                                    </div>
                                    <div className="content mt-2">
                                        <h4 className="title mb-0">Programming languages used by the developer</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="key-feature d-flex p-3 rounded shadow bg-white">
                                    <div className="icon text-center rounded-pill mr-3">
                                        <i className="mdi mdi-cryengine text-primary"></i>
                                    </div>
                                    <div className="content mt-2">
                                        <h4 className="title mb-0">Repositories</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="key-feature d-flex p-3 rounded shadow bg-white">
                                    <div className="icon text-center rounded-pill mr-3">
                                        <i className="mdi mdi-bootstrap text-primary"></i>
                                    </div>
                                    <div className="content mt-2">
                                        <h4 className="title mb-0">Level of knowledge in each programming language</h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="key-feature d-flex p-3 rounded shadow bg-white">
                                    <div className="icon text-center rounded-pill mr-3">
                                        <i className="mdi mdi-cube-outline text-primary"></i>
                                    </div>
                                    <div className="content mt-2">
                                        <h4 className="title mb-0">Business Technologies </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="key-feature d-flex p-3 rounded shadow bg-white">
                                    <div className="icon text-center rounded-pill mr-3">
                                        <i className="mdi mdi-sass text-primary"></i>
                                    </div>
                                    <div className="content mt-2">
                                        <h4 className="title mb-0">Team Size </h4>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-6 mt-4 pt-2">
                                <div className="key-feature d-flex p-3 rounded shadow bg-white">
                                    <div className="icon text-center rounded-pill mr-3">
                                        <i className="mdi mdi-check-decagram text-primary"></i>
                                    </div>
                                    <div className="content mt-2">
                                        <h4 className="title mb-0">Workplace</h4>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/*<div className="row justify-content-center">*/}
                        {/*    <div className="col-12 text-center mt-4 pt-2">*/}
                        {/*        <a href="#" className="btn btn-primary">See More <i*/}
                        {/*            className="mdi mdi-arrow-right"></i></a>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </section>

            </React.Fragment>

        );
    }
}

export default About;
