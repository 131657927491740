import React, { useEffect, useState } from 'react';
import Loading from '../../components/loading';
import { Link } from 'react-router-dom';
import api from '../../services/api';
import Carrossel from './carrossel';
import HomeAbout from "./about";
import CTA from "./CTA";
import Devs from "./devs";
import Companies from "./companies";

export default function Home({ history }) {

    const [companies, setCompanies] = useState([]);

    useEffect(() => {
        async function loadCompanies() {
          const token = localStorage.getItem('user');

          const spot = await api.get('/spots');
          setCompanies(spot.data.data);

        }

        loadCompanies();
    }, []);

    return (

        <React.Fragment>
            <Loading />
            <Carrossel/>
            <HomeAbout />
            <CTA/>
            <Devs />
            <Companies/>
        </React.Fragment>

    )
}
