import React from 'react';

const HomeAbout = () => {
    return (
    <section className="section">
        <div className="container">
            <div className="row align-items-center">
                <div className="col-lg-5 col-md-6 col-12">
                    <img src="/assets/images/about.jpg" className="img-fluid rounded" alt=""/>
                </div>

                <div className="col-lg-7 col-md-6 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div className="section-title ml-lg-4">
                        <h4 className="title mb-4">About Our Community</h4>
                        <p className="text-muted">We are a community of <span className="text-primary font-weight-bold">developers </span>
                          who have come together to generate knowledge and career possibilities. We enable experienced developers to stay ahead of the curve by connecting them to leading companies. We open doors to new talent, and help the market connect and grow with the best developers.
                        </p>
                        <a href="/login" className="btn btn-primary mt-3">Join now</a>
                    </div>
                </div>
            </div>
        </div>
    </section>

    )
};

export default HomeAbout;
