// src/components/NavBar.js

import React from "react";

import { isAuthenticated } from '../services/auth';

const NavBar = () => {

    return (

        <header id="topnav" className="defaultscroll sticky bg-white">
            <div className="container">

                <div>
                    <img style={{float: "left", marginTop: "15px"}} width="50px"
                         src="/assets/images/logo-blue.svg" alt="CodeNroll"/>
                        <a className="logo" href="/">CodeNroll</a>
                </div>


                <div className="menu-extras">
                    <div className="menu-item">

                        <a className="navbar-toggle">
                            <div className="lines">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </a>

                    </div>
                </div>

                <div id="navigation">

                    <ul className="navigation-menu">
                        <li>
                            <a href="/dashboard">Dashboard</a>
                        </li>
                        <li>
                            <a href="/companies">Companies</a>
                        </li>
                        <li>
                            <a href="/devs">Devs</a>
                        </li>
                        <li>
                            <a href="/contact">Contact</a>
                        </li>
                        <li>
                            {isAuthenticated() ? (
                                <a href="/logout"> Log out </a>
                            ) : (
                                <a href="/login" > Log in </a>
                            )}

                        </li>
                    </ul>

                </div>

            </div>

        </header>


    );
};

export default NavBar;
