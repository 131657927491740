import React from 'react';

const Carrossel = () => {
return (
    <section className="home-slider position-relative">
        <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
            <div className="carousel-inner">
                <div className="carousel-item active"
                     style={{backgroundImage:`url(/assets/images/bg01.jpg)`}}>
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 text-center">
                                        <div className="title-heading mt-4">
                                            <h6 className="text-light animated fadeInDownBig animation-delay-1">Coworking Space For a Success</h6>
                                            <h1 className="heading mb-3 text-white animated fadeInUpBig animation-delay-3">Work at the best tech companies for a day</h1>
                                            <p className="para-desc text-light mx-auto animated fadeInUpBig animation-delay-7">Schedule a company visit and get to know the IT staff and the technologies they use and stay up to date with their latest news.</p>
                                            <div className="mt-4 pt-2 animated fadeInUpBig animation-delay-11">
                                                <a href="/login" className="btn btn-primary mt-2">Get Started</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="carousel-item"
                     style={{backgroundImage:`url(/assets/images/bg02.jpg)`}}>
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 text-center">
                                        <div className="title-heading mt-4">
                                            <h6 className="text-light animated fadeInDownBig animation-delay-1">The best IT company in the city</h6>
                                            <h1 className="heading mb-3 text-white animated fadeInUpBig animation-delay-3">Find a workspace</h1>
                                            <p className="para-desc mx-auto text-light animated fadeInUpBig animation-delay-7">Working from home may sound perfect, but networking with the top professionals in the industry and understanding what companies are looking for is the best way to stay competitive.</p>
                                          <div className="mt-4 pt-2 animated fadeInUpBig animation-delay-11">
                                            <a href="/companies" className="btn btn-primary mt-2">Book Space</a>
                                          </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>


                <div className="carousel-item"
                     style={{backgroundImage:`url(/assets/images/bg03.jpg)`}}>
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 text-center">
                                        <div className="title-heading mt-4">
                                            <h6 className="text-light animated fadeInDownBig animation-delay-1">Our Space for You</h6>
                                            <h1 className="heading mb-3 text-white animated fadeInUpBig animation-delay-3">Try out your dream company</h1>
                                            <p className="para-desc mx-auto text-light animated fadeInUpBig animation-delay-7">Have you dreamed about working with one of the most connected teams and being part of that group? Schedule a visit to meet the company and team you admire most and get a taste for real.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a className="carousel-control-prev" href="#carouselExampleControls" role="button"
               data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
            </a>
            <a className="carousel-control-next" href="#carouselExampleControls" role="button"
               data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
            </a>
        </div>
    </section>
)
};

export default Carrossel;
