import React, { useEffect, useState } from 'react';
import Select from 'react-select'
import Loading from '../../components/loading';
import api from '../../services/api';
import Likes from "../../components/likes";

export default function ListDevs() {
    const [devs, setDevs] = useState([{}]);
    const [filteredDevs, setFilteredDevs] = useState([{}]);
    const [techs, setTechs] = useState([{}]);
    const [state, setState] = useState({ selected: { label: 'All', value: 'All'} });

    const [user, setUser] = useState(localStorage.getItem('user.id'));

    function filterCompaniesTechs(techs) {

        if(techs.value === "All") {
            setFilteredDevs(devs);
            return;
        }
        setState({selected: { label: techs.label, value: techs.value}});
        let result = [];
        devs.map(function (dev) {
            dev.techs.map(function (tech) {
                if(tech.tech === techs.value)
                result.push(dev);
            });
        });
        setFilteredDevs(result);

    }

    function calculateAge(since) {
        var ageDifMs = Date.now() - new Date(since);
        var ageDate = new Date(ageDifMs);
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }

    useEffect(() => {
        async function loadCompanies() {

            const resDevs = await api.get('/devs');
            const resTechs = await api.get('/devs/techs', {});

            setDevs(resDevs.data.data);
            setFilteredDevs(resDevs.data.data);

            let arr  = [{ label: 'All', value: 'All'}];
            await resTechs.data.data.map(function (tech) {
                arr.push({label: tech, value: tech});
            });

            setTechs(arr);
        }

        loadCompanies();

    }, []);

    return (

        <React.Fragment>

            <Loading />

            <section className="section">

                <div className="container">

                    <div className="row border-bottom">
                        <div className="col-lg-9 col-md-8">
                            <div className="section-title">
                                <h4 className="title mb-2">All Devs</h4>
                                <p className="text-muted mb-0">Most Relevance Devs by Techs</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="form custom-form">
                                <div className="form-group">
                                    <label>Types of techs :</label>
                                    <Select
                                        onChange={filterCompaniesTechs}
                                        value={state.selected}
                                        options={techs}
                                    />

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-12 mt-4 pt-2">
                            <div className="section-title">
                                <h5 className="mb-0">Recommended Devs :</h5>
                            </div>
                        </div>

                        {filteredDevs.map( dev => (

                            <div key={Math.random()} className="col-lg-3 col-md-6 col-12 mt-4 pt-2">

                                <div className="job-content p-4 border rounded position-relative overflow-hidden">
                                    <div className="head pb-3 border-bottom">

                                        <ul style={{marginTop: "-10px", fontSize: "12px", display: "grid", float: "right"}} className="list-unstyled mb-0">

                                            <li className="list-inline-item time float-right text-muted">
                                                <i className="mdi mdi-clock-outline"> </i>
                                                 Dev {calculateAge(dev.dev_since)} Year +
                                            </li>
                                            <li style={{textAlign: "right", textTransform:"capitalize"}} className="list-inline-item text-info">
                                                {dev.user ? dev.user.provider : null}
                                            </li>

                                        </ul>
                                        <div style={{width: "110px"}} className="position-relative">
                                            <img style={{width: "110px"}} src={dev.avatar} className="img-fluid d-block rounded-pill mx-auto" alt="" />
                                        </div>
                                        <h4><a href={dev.user ? `/dev/${dev.nickname}` : "#"} className="text-dark title">
                                            {dev.user ? dev.user.name : null}</a>
                                        </h4>

                                        <p className="mb-0">
                                            <a href="#" className="text-muted place">
                                                { dev.city }
                                            </a>
                                        </p>
                                    </div>
                                    <ul className="job-facts pt-3 list-unstyled" style={{marginLeft: "-25px"}}>

                                        {dev.techs ?
                                            dev.techs.map( tech => (

                                            <li  key={Math.random()} style={{width: "50%", fontSize: "12px"}} className="list-inline-item text-muted">
                                                {/*<i className="mdi mdi-check text-success mr-1"> </i>*/}
                                                <span style={{ marginLeft: "25px"}}>{tech.tech}</span>
                                                <div style={{height: "10px", marginLeft: "25px"}} className="progress">
                                                    {tech.punctuation > 10 ? (
                                                        <div className="progress-bar progress-bar-striped bg-gold"
                                                             role="progressbar" style={{width: tech.punctuation*10+"%"}} aria-valuenow={tech.punctuation*10+"%"}
                                                             aria-valuemin="0" aria-valuemax="100">
                                                        </div>
                                                    ) : (
                                                        tech.punctuation >= 5 ? (
                                                                <div className="progress-bar progress-bar-striped bg-success"
                                                                     role="progressbar" style={{width: tech.punctuation*10+"%"}} aria-valuenow={tech.punctuation*10+"%"}
                                                                     aria-valuemin="0" aria-valuemax="100">
                                                                </div> ) : (
                                                                    tech.punctuation >= 2.5 ? (
                                                                    <div className="progress-bar progress-bar-striped bg-info"
                                                                         role="progressbar" style={{width: tech.punctuation*10+"%"}} aria-valuenow={tech.punctuation*10+"%"}
                                                                         aria-valuemin="0" aria-valuemax="100">
                                                                    </div>
                                                                    ) : (
                                                                    <div className="progress-bar progress-bar-striped bg-danger"
                                                                         role="progressbar" style={{width: tech.punctuation*10+"%"}} aria-valuenow={tech.punctuation*10+"%"}
                                                                         aria-valuemin="0" aria-valuemax="100">
                                                                    </div>
                                                                    )
                                                        )
                                                    )

                                                    }


                                                </div>
                                            </li>

                                         )) : ""}

                                    </ul>

                                </div>
                            </div>


                        ))}


                    </div>

                </div>

            </section>

        </React.Fragment>
    )
}
