import React, { useEffect, useState } from 'react';
import Loading from '../../components/loading';
import Select from 'react-select'
import api from '../../services/api';
import Likes from "../../components/likes";

export default function ListCompanies() {
    const [companies, setCompanies] = useState([{}]);
    const [filteredCompanies, setFilteredCompanies] = useState([{}]);
    const [techs, setTechs] = useState([{}]);
    const [state, setState] = useState({ selected: { label: 'All', value: 'All'} });

    const [user, setUser] = useState(localStorage.getItem('user.id'));

    function filterCompaniesTechs(techs) {

        if(techs.value === "All") {
            setFilteredCompanies(companies);
            return;
        }
        setState({selected: { label: techs.label, value: techs.value}});
        let result = companies.filter(item => item.techs.some(({tech}) => tech === techs.value));
        setFilteredCompanies(result);

    }

    function stringToArray(string) {
        if(string !== undefined) {
            return string.toString().split(',');
        }
    }

    useEffect(() => {
        async function loadCompanies() {

            const resCompanies = await api.get('/spots');

            const resTechs = await api.get('/spots/techs', {});

            setCompanies(resCompanies.data.data);
            setFilteredCompanies(resCompanies.data.data);

            let arrTechs = resTechs.data.data.toString().split(',');
            let arr  = [{ label: 'All', value: 'All'}];
            arrTechs.forEach(function(v, d){
                arr.push({label: v.toString(), value: v.toString()});
            });
            setTechs(arr);

        }

        loadCompanies();

    }, []);

    return (

        <React.Fragment>

            <Loading />

            <section className="section">

                <div className="container">

                    <div className="row border-bottom">
                        <div className="col-lg-9 col-md-8">
                            <div className="section-title">
                                <h4 className="title mb-2">All Companies</h4>
                                <p className="text-muted mb-0">Most Relevance Companies by Techs</p>
                            </div>
                        </div>

                        <div className="col-lg-3 col-md-4 mt-4 mt-sm-0 pt-2 pt-sm-0">
                            <div className="form custom-form">
                                <div className="form-group">
                                    <label>Types of techs :</label>
                                    <Select
                                        onChange={filterCompaniesTechs}
                                        value={state.selected}
                                        options={techs}
                                    />

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">

                        <div className="col-12 mt-4 pt-2">
                            <div className="section-title">
                                <h5 className="mb-0">Recommended Companies :</h5>
                            </div>
                        </div>

                        {filteredCompanies.map( company => (

                            <div key={Math.random()} className="col-lg-4 col-md-6 col-12 mt-4 pt-2">
                                <div className="blog position-relative overflow-hidden shadow rounded">
                                    <div className="position-relative">
                                        <img src={company.image ? company.image.path : '#'} className="img-fluid rounded-top" alt=""/>
                                        <div className="overlay rounded-top bg-dark"></div>
                                    </div>
                                    <div className="content p-4">
                                        <h4><a href="#" className="title text-dark">{company.name} </a></h4>
                                        <div className="post-meta mt-3">

                                            <ul className="list-unstyled mb-0">
                                                <li className="list-inline-item mr-2">
                                                    <a href="#" className="text-muted like">
                                                        {/*{company.price ? `R$${company.price}/dia` : 'Free'}*/}
                                                        {company.about}
                                                    </a>
                                                </li>

                                            </ul>
                                            <ul className="job-facts pt-3 list-unstyled">

                                                {company.techs &&
                                                    company.techs.map( t => (
                                                        <li key={Math.random()}  className="list-inline-item text-muted">
                                                        <i className="mdi mdi-check text-success mr-1"> </i>
                                                         {t.tech}&nbsp;
                                                        </li>
                                                ))}
                                            </ul>

                                        </div>
                                        <Likes
                                            likes={company.likes ? company.likes.length : 0}
                                            spot={company.id}
                                            updated={company.likes ? company.likes.some(({user_id}) => user_id === user) : false}
                                            booking={true}
                                        />

                                    </div>
                                    {company.address &&
                                    <div className="author">
                                        <small className="text-light user d-block">
                                            <i className="mdi mdi-bank-outline"></i>
                                            {company.address.street_name}, {company.address.street_number}
                                        </small>
                                        <small className="text-light date">
                                            <i className="mdi mdi-city-variant"></i>
                                            {company.address.city}
                                        </small>

                                    </div>
                                    }

                                </div>
                            </div>



                        ))}


                    </div>

                </div>

            </section>

        </React.Fragment>
    )
}
