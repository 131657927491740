import React from 'react';

const CTA = () => {
    return (
        <section className="section">
            <div className="container">
                <div className="bg-light rounded">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <img src="/assets/images/cta.jpg" className="rounded img-fluid" alt=""/>
                        </div>

                        <div className="col-lg-6 text-center">
                            <div className="section-title p-5">
                                {/*<a href="http://vimeo.com/287684225"*/}
                                {/*   className="play-btn mt-0 border text-center video-play-icon">*/}
                                {/*    <i className="mdi mdi-play text-dark"></i>*/}
                                {/*</a>*/}
                                <h4 className="main-title mb-4 mt-4">We are creative developers and co-workers</h4>
                                <p className="text-muted mx-auto para-desc mb-0">Today's developer doesn't just survive on their technical knowledge. You have to collaborate, work as a team, and, most importantly, be part of a company you admire. That's what takes you to the next level.</p>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </section>

)
};

export default CTA;
