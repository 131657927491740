import React from 'react';
import NavBar from "./components/navBar";
import Routes from "./routes";
import GlobalStyle from './styles/global';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

require('dotenv').config();

function App() {
    return (
        <div className="App">
            <NavBar />
            <Routes />
            <GlobalStyle />
        </div>
    );
}

// const App = () => <Routes />;
export default App;
