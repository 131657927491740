import React from 'react';
import { Redirect } from "react-router-dom";
import api from "../../../services/api";
import GitHubLogin from 'react-github-login';
import 'font-awesome/css/font-awesome.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-social/bootstrap-social.css';
import './login.css';

import {isAuthenticated, login} from "../../../services/auth";

export default class GitLogin extends React.Component {

    constructor(props) {
        super(props);
    }


    async onSuccess(response) {

        try {
            await api.post('/devs/github', { 'code': response.code })
                .then(async result => {
                    if (result.status === 200) {
                        localStorage.setItem("user.name", result.data.data.name);
                        localStorage.setItem("user.email", result.data.data.email);
                        localStorage.setItem("user.id", result.data.data.id);
                        localStorage.setItem("user.role", result.data.data.role);
                        await login(result.data.data.token);
                    }
                }).catch(e => {
                    console.log(e.message);
                    // setError(true);
                });

        } catch (e) {
            alert(e.message);
        }
    }

    onFailure = response => console.error(response);

    render(){

        return (
            <GitHubLogin
                clientId="1da307652bf7a002eac4"
                redirectUri="http://localhost:3001/auth/github/callback"
                onSuccess={this.onSuccess}
                onFailure={this.onFailure}
                className={" btn btn-social btn-github fa fa-github"}
                buttonText={" Sign in with Github"}
            />
        )
    }
}

