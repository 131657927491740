import React from 'react';
import Loading from "../../components/loading";

class Services extends React.Component {

    render(){

        return(

            <React.Fragment>

                <Loading />

                <section className="bg-half bg-light">
                    <div className="home-center">
                        <div className="home-desc-center">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 text-center">
                                        <div className="page-next-level">
                                            <h4 className="title"> Services </h4>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4 col-12">
                                <div className="features mt-5">
                                    <div className="image position-relative d-inline-block">
                                        <img src="images/icon/pen.svg" alt=""/>
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">Developer Page</h4>
                                        <p className="text-muted mb-0">A new way to create your résumé and present your knowledge base.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-12 mt-5">
                                <div className="features">
                                    <div className="image position-relative d-inline-block">
                                        <img src="images/icon/video.svg" alt=""/>
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">Company Page</h4>
                                        <p className="text-muted mb-0">Show your business to the developer community, introducing your team and the technologies you use.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-12 mt-5">
                                <div className="features">
                                    <div className="image position-relative d-inline-block">
                                        <img src="images/icon/intellectual.svg" alt=""/>
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">Space reservation within technology companies</h4>
                                        <p className="text-muted mb-0">Our test-drive format allows developers to take a look at the best tech companies out there.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-12 mt-5">
                                <div className="features">
                                    <div className="image position-relative d-inline-block">
                                        <img src="images/icon/user.svg" alt=""/>
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">Search for companies and developers</h4>
                                        <p className="text-muted mb-0">Find companies and developers filtered by technology, and create your match.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-12 mt-5">
                                <div className="features">
                                    <div className="image position-relative d-inline-block">
                                        <img src="images/icon/calendar.svg" alt=""/>
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">Availability of vacancies</h4>
                                        <p className="text-muted mb-0">Coming soon.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-4 col-12 mt-5">
                                <div className="features">
                                    <div className="image position-relative d-inline-block">
                                        <img src="images/icon/sand-clock.svg" alt=""/>
                                    </div>

                                    <div className="content mt-4">
                                        <h4 className="title-2">Online Hiring</h4>
                                        <p className="text-muted mb-0">Coming soon.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*<div className="container mt-100 mt-60">*/}
                    {/*    <div className="row justify-content-center">*/}
                    {/*        <div className="col-12 text-center">*/}
                    {/*            <div className="section-title mb-60">*/}
                    {/*                <h4 className="main-title mb-4">Client Reviews</h4>*/}
                    {/*                <p className="text-muted para-desc mx-auto mb-0">Start working with <span*/}
                    {/*                    className="text-primary font-weight-bold">Landrick</span> that can provide*/}
                    {/*                    everything you need to generate awareness, drive traffic, connect.</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className="row justify-content-center">*/}
                    {/*        <div className="col-lg-12 text-center">*/}
                    {/*            <div id="customer-testi" className="owl-carousel owl-theme owl-loaded owl-drag">*/}


                    {/*                <div className="owl-stage-outer">*/}
                    {/*                    <div className="owl-stage"*/}
                    {/*                         style={{transform: "translate3d(-1480px, 0px, 0px)", transition: "all 0.25s ease 0s", width: "4440px"}}>*/}
                    {/*                        <div className="owl-item cloned" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/04.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" According to most sources,*/}
                    {/*                                            Lorum Ipsum can be traced back to a text composed by*/}
                    {/*                                            Cicero. "</p>*/}
                    {/*                                        <h6 className="text-primary">- Christa Smith <small*/}
                    {/*                                            className="text-muted">Manager</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="owl-item cloned" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/05.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" There is now an abundance*/}
                    {/*                                            of readable dummy texts. These are usually used when a*/}
                    {/*                                            text is required. "</p>*/}
                    {/*                                        <h6 className="text-primary">- Dean Tolle <small*/}
                    {/*                                            className="text-muted">Developer</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="owl-item cloned" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/06.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" Thus, Lorem Ipsum has only*/}
                    {/*                                            limited suitability as a visual filler for German texts.*/}
                    {/*                                            "</p>*/}
                    {/*                                        <h6 className="text-primary">- Jill Webb <small*/}
                    {/*                                            className="text-muted">Designer</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="owl-item" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/01.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" It seems that only*/}
                    {/*                                            fragments of the original text remain in the Lorem Ipsum*/}
                    {/*                                            texts used today. "</p>*/}
                    {/*                                        <h6 className="text-primary">- Thomas Israel <small*/}
                    {/*                                            className="text-muted">C.E.O</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="owl-item active" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/03.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star-half text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" One disadvantage of Lorum*/}
                    {/*                                            Ipsum is that in Latin certain letters appear more*/}
                    {/*                                            frequently than others. "</p>*/}
                    {/*                                        <h6 className="text-primary">- Barbara McIntosh <small*/}
                    {/*                                            className="text-muted">M.D</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="owl-item active" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/02.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" The most well-known dummy*/}
                    {/*                                            text is the 'Lorem Ipsum', which is said to have*/}
                    {/*                                            originated in the 16th century. "</p>*/}
                    {/*                                        <h6 className="text-primary">- Carl Oliver <small*/}
                    {/*                                            className="text-muted">P.A</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="owl-item active" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/04.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" According to most sources,*/}
                    {/*                                            Lorum Ipsum can be traced back to a text composed by*/}
                    {/*                                            Cicero. "</p>*/}
                    {/*                                        <h6 className="text-primary">- Christa Smith <small*/}
                    {/*                                            className="text-muted">Manager</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="owl-item" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/05.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" There is now an abundance*/}
                    {/*                                            of readable dummy texts. These are usually used when a*/}
                    {/*                                            text is required. "</p>*/}
                    {/*                                        <h6 className="text-primary">- Dean Tolle <small*/}
                    {/*                                            className="text-muted">Developer</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="owl-item" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/06.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" Thus, Lorem Ipsum has only*/}
                    {/*                                            limited suitability as a visual filler for German texts.*/}
                    {/*                                            "</p>*/}
                    {/*                                        <h6 className="text-primary">- Jill Webb <small*/}
                    {/*                                            className="text-muted">Designer</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="owl-item cloned" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/01.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" It seems that only*/}
                    {/*                                            fragments of the original text remain in the Lorem Ipsum*/}
                    {/*                                            texts used today. "</p>*/}
                    {/*                                        <h6 className="text-primary">- Thomas Israel <small*/}
                    {/*                                            className="text-muted">C.E.O</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="owl-item cloned" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/03.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star-half text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" One disadvantage of Lorum*/}
                    {/*                                            Ipsum is that in Latin certain letters appear more*/}
                    {/*                                            frequently than others. "</p>*/}
                    {/*                                        <h6 className="text-primary">- Barbara McIntosh <small*/}
                    {/*                                            className="text-muted">M.D</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                        <div className="owl-item cloned" style={{width: "370px"}}>*/}
                    {/*                            <div className="customer-testi m-2 text-left">*/}
                    {/*                                <img src="images/client/02.jpg" height="80"*/}
                    {/*                                     className="rounded shadow float-left mr-3" alt=""/>*/}
                    {/*                                    <div*/}
                    {/*                                        className="content overflow-hidden d-block p-3 shadow rounded bg-white">*/}
                    {/*                                        <ul className="list-unstyled mb-0">*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                            <li className="list-inline-item"><i*/}
                    {/*                                                className="mdi mdi-star text-warning"></i></li>*/}
                    {/*                                        </ul>*/}
                    {/*                                        <p className="text-muted mt-2">" The most well-known dummy*/}
                    {/*                                            text is the 'Lorem Ipsum', which is said to have*/}
                    {/*                                            originated in the 16th century. "</p>*/}
                    {/*                                        <h6 className="text-primary">- Carl Oliver <small*/}
                    {/*                                            className="text-muted">P.A</small></h6>*/}
                    {/*                                    </div>*/}
                    {/*                            </div>*/}
                    {/*                        </div>*/}
                    {/*                    </div>*/}
                    {/*                </div>*/}
                    {/*                <div className="owl-nav disabled">*/}
                    {/*                    <button type="button" role="presentation" className="owl-prev"><span*/}
                    {/*                        aria-label="Previous">‹</span></button>*/}
                    {/*                    <button type="button" role="presentation" className="owl-next"><span*/}
                    {/*                        aria-label="Next">›</span></button>*/}
                    {/*                </div>*/}
                    {/*                <div className="owl-dots">*/}
                    {/*                    <button role="button" className="owl-dot active"><span></span></button>*/}
                    {/*                    <button role="button" className="owl-dot"><span></span></button>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*    </div>*/}

                    {/*</div>*/}

                </section>

            </React.Fragment>

        );
    }
}

export default Services;
